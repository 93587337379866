import { Script } from 'gatsby';

const AppsflyerSmartScript = () => (
  <Script id="smart-script" data-testid="smart-script">
    {`
      var oneLinkURL = "https://go.onelink.me/YWVv";
      var webReferrer = "af_channel";
      var mediaSource = {keys:["utm_source"],defaultValue:"any_source"};
      var campaign = {keys:["utm_campaign"],defaultValue:"any_campaign_name"};
      var ad = {keys:["utm_ad"],defaultValue:"any_ad_name"};
      var adSet = {keys:["utm_adset"],defaultValue:"any_adset_name"};
      var custom_ss_ui = {paramKey:"af_ss_ui",defaultValue:"true"};
      var result = window.AF_SMART_SCRIPT?.generateOneLinkURL({
          oneLinkURL: oneLinkURL,
          webReferrer: webReferrer,
          afParameters: {
              mediaSource: mediaSource,
              campaign: campaign,
              ad: ad,
              adSet: adSet,
              afCustom: [
                  custom_ss_ui
              ]
          }
      });
      
      window.sessionStorage.setItem('inviteLink', result?.clickURL);
    `}
  </Script>
);

export default AppsflyerSmartScript;
